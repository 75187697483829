import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import Grid from '@mui/material/Grid'
import { Button } from '../../features/section-components/Button'
import styles from './EditPhoneNumber.module.scss'
import { SectionHeader } from '../../features/section-components/SectionHeader'
import JNJFooter from '../../features/jnj-footer/JNJFooter'
// import Alert from '../../features/error/Alert'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import { LoadingIcon } from '../../features/section-components/LoadingIcon'
import { TextboxMobile } from '../../features/section-components/Textbox'

function EditPhoneMobile({
  userCallStatus, textMaskCustom, phoneNumber, handleSubmit, handleChange, t
}) {
  return (
    <MarginWrapper>
      <LoadingIcon isLoading={userCallStatus === 'loading' || userCallStatus === 'failed' || userCallStatus === 'idle'}>
        <div className={styles['edit-phone-mobile']}>
          <div className={styles['edit-phone-container-mobile']}>
            <div className={styles['home-section']}>
              <Link to="/home" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', }} color="black">
                <ArrowBackOutlinedIcon className={styles['home-icon']} sx="medium" />
                <div className={styles['home-text']}>
                  {t('home-tab')}
                </div>
              </Link>
            </div>
            {/* <div className={styles['alert-container']}>
                        <Alert
                            message="Phone number updated!"
                            onClose={() => { }}
                            alertStyle="success-alert"
                            headerText="Success"
                        />
                    </div> */}
            <SectionHeader text={t('label-phone-num')} size="medium" />
            <span className={styles['form-secondary-text-mobile']}>{t('support-text-phone-auth')}</span>
            <TextboxMobile
              value={phoneNumber}
              inputComponent={textMaskCustom}
              supportText={t('validation-phone')}
              onChange={(e) => handleChange(e.target.value)}
              required
            />
            <Grid item xs={12} sm={12} md={12} lg={10} xl={10} className={styles['edit-phone-buttons']}>
              <Button
                text={t('button-save-changes')}
                onClick={() => handleSubmit()}
                buttonStyle="cta-red-oval"
              />
              <Link
                to="/home"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  text={t('button-cancel')}
                  buttonStyle="filled-grey-oval"
                />
              </Link>
            </Grid>
          </div>
          <div className={styles['JNJ-footer']}>
            <JNJFooter />
          </div>
        </div>
      </LoadingIcon>
    </MarginWrapper>
  )
}

EditPhoneMobile.propTypes = {
  t: PropTypes.func.isRequired,
  userCallStatus: PropTypes.string.isRequired,
  textMaskCustom: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

export default EditPhoneMobile
