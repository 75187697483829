import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import { FormControl, OutlinedInput, FormHelperText } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import { Button } from '../../features/section-components/Button'
import { SectionHeader } from '../../features/section-components/SectionHeader'
import { TextboxDesktop } from '../../features/section-components/Textbox/TextboxDesktop'
import styles from './Support.module.scss'
import { LoadingIcon } from '../../features/section-components/LoadingIcon'
import JNJFooter from '../../features/jnj-footer/JNJFooter'
import { sendMail, getSupportCallStatus, resetStatus } from './supportSlice'
import Alert from '../../features/error/Alert'

function SupportDesktop({
  t
}) {
  const supportCallStatus = useSelector(getSupportCallStatus)
  const dispatch = useDispatch()

  const [userFields, setUserFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  })
  const [validFields, setValidFields] = useState({
    firstName: { isValid: false, hasError: false },
    lastName: { isValid: false, hasError: false },
    email: { isValid: false, hasError: false },
    phone: { isValid: true, hasError: false },
    message: { isValid: false, hasError: false },
  })

  const isFormValid = Object.values(validFields).every((field) => field.isValid)
  const nonRequiredFields = ['phone']

  const handleBlur = (field) => {
    if (!validFields[field].isValid || (!nonRequiredFields.includes(field) && userFields[field] === '')) {
      setValidFields((prev) => ({ ...prev, [field]: { isValid: false, hasError: true } }))
    }
  }

  const handleSubmit = () => {
    if (!isFormValid) return
    dispatch(sendMail({
      firstName: userFields.firstName,
      lastName: userFields.lastName,
      email: userFields.email,
      phone: userFields.phone,
      body: userFields.message
    }))
  }

  const validateRegex = (field, newValue) => {
    const regexStrings = {
      email: /^(\S+@\S+\.\S+)$/,
      phone: /^\+?\d+$/,
    }
    return regexStrings[field].test(newValue)
  }

  const validateChange = (field, newValue) => {
    setUserFields({ ...userFields, [field]: newValue })
    if (nonRequiredFields.includes(field) && (!newValue || newValue.trim() === '')) {
      setValidFields((prev) => ({ ...prev, [field]: { isValid: true, hasError: false } }))
      return
    }
    const regexFields = ['email', 'phone']
    setValidFields((prev) => {
      if (regexFields.includes(field)) {
        const regexIsValid = validateRegex(field, newValue)
        return { ...prev, [field]: { isValid: regexIsValid, hasError: !regexIsValid } }
      }
      if (newValue && newValue.trim() !== '') {
        return { ...prev, [field]: { isValid: true, hasError: false } }
      }
      return { ...prev, [field]: { isValid: false, hasError: false } }
    })
  }

  const theme = createTheme({
    typography: {
      fontFamily: '"JohnsonText", Arial, sans-serif',
    }
  })

  const handleSendAnotherMessage = () => {
    dispatch(resetStatus())
    setUserFields({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: ''
    })
    setValidFields({
      firstName: { isValid: false, hasError: false },
      lastName: { isValid: false, hasError: false },
      email: { isValid: false, hasError: false },
      phone: { isValid: true, hasError: false },
      message: { isValid: false, hasError: false }
    })
  }

  useEffect(() => {
    if (supportCallStatus === 'idle') {
      setUserFields({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: ''
      })
      setValidFields({
        firstName: { isValid: false, hasError: false },
        lastName: { isValid: false, hasError: false },
        email: { isValid: false, hasError: false },
        phone: { isValid: true, hasError: false },
        message: { isValid: false, hasError: false }
      })
    }
  }, [supportCallStatus])

  return (
    <MarginWrapper>
      <LoadingIcon isLoading={supportCallStatus === 'loading'}>
        <div className={styles['home-section']}>
          <Link to="/home" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', }} color="black">
            <ChevronLeftOutlinedIcon className={styles['home-icon']} sx={{ width: '.6em' }} />
            <div className={styles['home-text']}>
              {t('home-tab')}
            </div>
          </Link>
        </div>
        <div className={styles.header}>
          <SectionHeader text={t('support-tab')} size="large" />
        </div>
        <div className={styles['help-message']}>
          {t('support-help-message')}
        </div>
        <div className={styles['middle-section']}>
          <div className={styles['contact-message']}>
            <SectionHeader text={t('support-contact-message')} size="medium" />
          </div>
          {supportCallStatus === 'succeeded' && (
            <div className={styles['success-view-desktop']}>
              <Alert
                alertStyle="success-alert"
                headerText={t('support-success-header')}
                blurb={t('support-success-blurb')}
                onClose={() => {}}
                showClose
              />
              <div className={styles['success-view-desktop-button']}>
                <Button
                  text={t('support-send-another')}
                  buttonStyle="cta-red-oval-medium"
                  onClick={handleSendAnotherMessage}
                />
              </div>
            </div>
          )}
          {supportCallStatus !== 'succeeded' && (
          <>
            <div className={validFields.firstName.hasError ? styles['field-error'] : styles.field}>
              <label htmlFor="firstName" className={styles['form-label']}>{t('support-label-first-name')}</label>
              <div className={styles.input}>
                <TextboxDesktop
                  value={userFields.firstName}
                  onChange={(e) => validateChange('firstName', e.target.value)}
                  onBlur={() => handleBlur('firstName')}
                  error={validFields.firstName.hasError}
                  supportText={t('label-mandatory-field')}
                  required
                />
              </div>
            </div>
            <div className={validFields.lastName.hasError ? styles['field-error'] : styles.field}>
              <label htmlFor="lastName" className={styles['form-label']}>{t('support-label-last-name')}</label>
              <div className={styles.input}>
                <TextboxDesktop
                  value={userFields.lastName}
                  onChange={(e) => validateChange('lastName', e.target.value)}
                  onBlur={() => handleBlur('lastName')}
                  error={validFields.lastName.hasError}
                  supportText={t('label-mandatory-field')}
                  required
                />
              </div>
            </div>
            <div className={validFields.email.hasError ? styles['field-error'] : styles.field}>
              <label htmlFor="email" className={styles['form-label']}>{t('support-label-email')}</label>
              <div className={styles.input}>
                <TextboxDesktop
                  value={userFields.email}
                  onChange={(e) => validateChange('email', e.target.value)}
                  onBlur={() => handleBlur('email')}
                  error={validFields.email.hasError}
                  supportText={userFields.email === '' ? t('label-mandatory-field') : t('validation-email')}
                  required
                />
              </div>
            </div>
            <div className={validFields.phone.hasError ? styles['field-error'] : styles.field}>
              <label htmlFor="phone" className={styles['form-label']}>{t('support-label-phone')}</label>
              <div className={styles.input}>
                <TextboxDesktop
                  value={userFields.phone}
                  onChange={(e) => validateChange('phone', e.target.value)}
                  onBlur={() => handleBlur('phone')}
                  error={validFields.phone.hasError}
                  supportText={t('support-validation-phone')}
                />
              </div>
            </div>
            <div className={validFields.message.hasError ? styles['field-error'] : styles.field}>
              <label htmlFor="message" className={styles['form-label']}>{t('support-label-message')}</label>
              <div className={styles.message}>
                <ThemeProvider theme={theme}>
                  <FormControl error={validFields.message.hasError} className={styles['form-control']}>
                    <OutlinedInput
                      variant="outlined"
                      multiline
                      minRows={3}
                      maxRows={3}
                      className={styles.textfield}
                      value={userFields.message}
                      onChange={(e) => validateChange('message', e.target.value)}
                      onBlur={() => handleBlur('message')}
                      error={validFields.message.hasError}
                      required
                      sx={{
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#4545E0',
                        },
                      }}
                    />
                    <FormHelperText>
                      {validFields.message.hasError ? t('label-mandatory-field') : ''}
                    </FormHelperText>
                  </FormControl>
                </ThemeProvider>
              </div>
            </div>
            <div className={styles['send-message']}>
              <Button
                text={(
                  <span className={styles['button-content']}>
                    {t('support-send')}
                    <ArrowForwardIcon />
                  </span>
              )}
                buttonStyle="cta-red-oval"
                onClick={handleSubmit}
                disabled={!isFormValid}
              />
            </div>
          </>
          )}
        </div>
        <div className={styles['jnj-footer']}>
          <JNJFooter text={t('FooterText')} footerSize="medium" />
        </div>
      </LoadingIcon>
    </MarginWrapper>
  )
}

SupportDesktop.propTypes = {
  t: PropTypes.func.isRequired,
}

export default SupportDesktop
