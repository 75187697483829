import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { QuestionRenderer } from './QuestionRenderer'
import styles from './DynamicSurvey.module.scss'
import { setSurveyResponses } from '../../../pages/Survey/surveySlice'

function DynamicSurvey({
  surveySchema, userObjData, buttonColors, onChange, customClass, currentPage
}) {
  const preferredLanguage = userObjData.preferredLanguage || 'en-US'
  const dispatch = useDispatch()
  const responses = useSelector((state) => state.survey.surveyResponses)

  const [localResponses, setLocalResponses] = useState(responses)

  const handleAnswerChange = (questionId, answer) => {
    const updatedResponses = { ...localResponses, [questionId]: answer }
    setLocalResponses(updatedResponses)
    onChange(updatedResponses)

    dispatch(setSurveyResponses(updatedResponses))
  }

  const getTranslation = (textObject) => textObject[preferredLanguage] || textObject['en-US']

  return (
    <div className={styles.surveyContainer}>
      {surveySchema.questions.map((question) => (
        (question.page === currentPage || question.page == null)
        && (
          <QuestionRenderer
            key={question.id}
            question={question}
            responses={localResponses}
            buttonColors={buttonColors}
            onAnswerChange={handleAnswerChange}
            getTranslation={getTranslation}
            customClass={customClass}
          />
        )
      ))}
    </div>
  )
}

DynamicSurvey.propTypes = {
  surveySchema: PropTypes.shape({
    id: PropTypes.string.isRequired,
    schema: PropTypes.string.isRequired,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['emotion_select', 'free_response']).isRequired,
        label: PropTypes.objectOf(PropTypes.string).isRequired,
        answers: PropTypes.arrayOf(
          PropTypes.shape({
            'en-US': PropTypes.string,
            'es-ES': PropTypes.string.isRequired,
            'ja-JP': PropTypes.string.isRequired,
            'ko-KR': PropTypes.string.isRequired,
            'nl-NL': PropTypes.string.isRequired,
            'de-DE': PropTypes.string.isRequired,
            'pt-PT': PropTypes.string.isRequired,
            'fr-FR': PropTypes.string.isRequired,
          })
        ),
        required: PropTypes.bool,
        dependentOn: PropTypes.shape({
          id: PropTypes.string.isRequired,
          answer: PropTypes.arrayOf(PropTypes.number).isRequired,
        }),
      })
    ).isRequired,
  }).isRequired,
  userObjData: PropTypes.shape({
    preferredLanguage: PropTypes.string,
    firstName: PropTypes.string,
  }).isRequired,
  buttonColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  customClass: PropTypes.string,
  currentPage: PropTypes.number
}

DynamicSurvey.defaultProps = {
  customClass: '',
  currentPage: 0
}

export default DynamicSurvey
