import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IMaskInput } from 'react-imask'
import PropTypes from 'prop-types'
import {
  fetchUser, selectUserCallStatus, selectUserObject, updateUser
} from '../../features/user/userSlice'
import { convertPhoneToE164, formatPhoneToNational } from '../../util/phoneUtils'
import '../../util/i18n'

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
// to eliminate prop spreading on the IMaskInput component, one would have to include
// at least 20 or 30 properties to pass in
// easier to just override the no prop spreading rule for such a trivial component
const textMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask="(000) 000-0000"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      onChange={() => { }}
    />
  )
})

const EditPhoneManager = () => {
  const dispatch = useDispatch()
  const userObj = useSelector(selectUserObject)
  const userCallStatus = useSelector(selectUserCallStatus)
  const [actualPhoneNumber, setActualPhoneNumber] = useState(
    formatPhoneToNational(userObj.mobilePhone)
  )
  const navigate = useNavigate()

  const [userFields, setUserFields] = useState({
    firstName: userObj.firstName ?? '',
    lastName: userObj.lastName ?? '',
    email: userObj.email ?? '',
    secondEmail: userObj.secondEmail ?? '',
    mobilePhone: formatPhoneToNational(userObj.mobilePhone) ?? '',
  })

  useEffect(() => {
    if (userCallStatus === 'idle') {
      dispatch(fetchUser())
    }
  }, [userCallStatus, dispatch])

  const handleSubmit = () => {
    const formattedUserFields = {
      ...userFields,
      mobilePhone: convertPhoneToE164(actualPhoneNumber),
    }

    dispatch(updateUser(formattedUserFields))
      .unwrap()
      .then(() => {
        navigate('/account')
      })
      .catch(() => {
      })
  }

  const handleChange = (value) => {
    const formatedValue = formatPhoneToNational(value)
    setActualPhoneNumber(formatedValue)
  }

  return {
    textMaskCustom,
    handleSubmit,
    actualPhoneNumber,
    handleChange,
    userObj
  }
}

textMaskCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}

export default EditPhoneManager
