import React from 'react'
import { useTranslation } from 'react-i18next'
import '../../util/i18n'
import { useSelector } from 'react-redux'
import { selectUserCallStatus } from '../../features/user/userSlice'
import isMobileView from '../../util/MobileView'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import EditPhoneDesktop from './EditPhoneDesktop'
import EditPhoneMobile from './EditPhoneMobile'
import EditPhoneManager from './EditPhoneManager'

export function EditPhoneNumber() {
  const { t } = useTranslation()
  const userCallStatus = useSelector(selectUserCallStatus)

  const {
    handleSubmit,
    textMaskCustom,
    actualPhoneNumber,
    handleChange,
    userObj
  } = EditPhoneManager()

  return (
    <MarginWrapper>
      {isMobileView() ? (
        <EditPhoneMobile
          userCallStatus={userCallStatus}
          userObj={userObj}
          textMaskCustom={textMaskCustom}
          phoneNumber={actualPhoneNumber}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          t={t}
        />
      ) : (
        <EditPhoneDesktop
          userCallStatus={userCallStatus}
          userObj={userObj}
          textMaskCustom={textMaskCustom}
          phoneNumber={actualPhoneNumber}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          t={t}
        />
      )}
    </MarginWrapper>
  )
}
