import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/system/Unstable_Grid/Grid'
import { Link } from 'react-router-dom'
import { Button } from '../../section-components/Button'
import { TextboxDesktop } from '../../section-components/Textbox'
import styles from './Form.module.scss'

function FormDesktop({
  t,
  userFields,
  validFields,
  validateChange,
  handleBlur,
  handleSubmit,
  isFormValid,
  TextMaskCustom,
  onCancel
}) {
  return (
    <div className={styles['edit-account-form']}>
      <label htmlFor="fieldsHeader" className={styles['fields-header']}>{t('fields-required')}</label>
      <div className={styles['form-field']}>
        <label htmlFor="firstName" className={styles['form-label']}>{t('label-first-name')}</label>
        <TextboxDesktop
          value={userFields.firstName}
          onChange={(e) => validateChange('firstName', e.target.value)}
          onBlur={() => handleBlur('firstName')}
          error={validFields.firstName.hasError}
          supportText={t('label-mandatory-field')}
          required
        />
      </div>
      <div className={styles['form-field']}>
        <label htmlFor="lastName" className={styles['form-label']}>{t('label-last-name')}</label>
        <TextboxDesktop
          value={userFields.lastName}
          onChange={(e) => validateChange('lastName', e.target.value)}
          onBlur={() => handleBlur('lastName')}
          error={validFields.lastName.hasError}
        />
      </div>
      <div className={styles['form-field']}>
        <label htmlFor="primaryEmail" className={styles['form-label']}>{t('label-email')}</label>
        <span className={styles['form-secondary-text']}>{t('support-text-primary-email')}</span>
        <TextboxDesktop
          disabled
          value={userFields.email}
          onBlur={() => handleBlur('email')}
          error={validFields.email.hasError}
          supportText={t('validation-email')}
          required
        />
      </div>
      <div className={styles['form-field']}>
        <label htmlFor="secondaryEmail" className={styles['form-label']}>{t('label-second-email-optional')}</label>
        <span className={styles['form-secondary-text']}>{t('support-text-second-email')}</span>
        <TextboxDesktop
          value={userFields.secondEmail}
          onChange={(e) => validateChange('secondEmail', e.target.value)}
          onBlur={() => handleBlur('secondEmail')}
          error={validFields.secondEmail.hasError}
          supportText={t('validation-email')}
        />
      </div>
      <div className={styles['form-field']}>
        <label htmlFor="phoneNumber" className={styles['form-label']}>{t('label-phone-num-secondary-auth')}</label>
        <span className={styles['form-secondary-text']}>{t('support-text-phone-auth')}</span>
        <TextboxDesktop
          value={userFields.mobilePhone}
          inputComponent={TextMaskCustom}
          onChange={(e) => validateChange('mobilePhone', e.target.value)}
          onBlur={() => handleBlur('mobilePhone')}
          error={validFields.mobilePhone.hasError}
          supportText={t('validation-phone')}
        />
        <Grid className={styles['manage-button']}>
          <Link to="/edit-phone" style={{ display: 'inline-block' }}>
            <Button text={t('label-edit-phone-number')} buttonStyle="cta-grey-oval" />
          </Link>
        </Grid>
      </div>
      <div className={styles.buttons}>
        <Button
          text={t('button-save-changes')}
          buttonStyle="cta-red-oval-edit-profile"
          onClick={handleSubmit}
          disabled={!isFormValid}
        />
        <Button
          text={t('button-cancel')}
          buttonStyle="cta-grey-oval"
          onClick={onCancel}
        />
      </div>
    </div>
  )
}

FormDesktop.propTypes = {
  t: PropTypes.func.isRequired,
  userFields: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    secondEmail: PropTypes.string,
    mobilePhone: PropTypes.string,
  }).isRequired,
  validFields: PropTypes.shape({
    firstName: PropTypes.shape({
      isValid: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
    lastName: PropTypes.shape({
      isValid: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
    email: PropTypes.shape({
      isValid: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
    secondEmail: PropTypes.shape({
      isValid: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
    mobilePhone: PropTypes.shape({
      isValid: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
  }).isRequired,
  validateChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  TextMaskCustom: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default FormDesktop
