import React from 'react'
import { IconButton, Box } from '@mui/material'
import PropTypes from 'prop-types'
import AlertStyles from './AlertStyles'

function Alert({
  message, onClose, alertStyle, headerText, blurb, showClose, customStyles
}) {
  const [open, setOpen] = React.useState(true)

  const handleClose = () => {
    setOpen(false)
    onClose() // Call the onClose prop function to inform the parent component
  }
  if (!open) return null

  const {
    backgroundColor, iconComponent, style, header, messageStyle, close
  } = AlertStyles({ alertStyle, headerText })

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: 2,
        width: 'match-parent',
        backgroundColor,
        color: '#000000',
        ...style,
        ...customStyles
      }}
    >
      {blurb ? (
        <div style={{ display: 'flex', alignItems: 'baseline', paddingBottom: '33px' }}>
          {/* change the padding for the icon when with blurb */}
          {iconComponent}
        </div>
      ) : (
        (iconComponent)
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
        {header && (
        <Box
          component="span"
          sx={{
            fontWeight: 'bold', fontSize: 'medium', textAlign: 'left', lineHeight: 1.5
          }}
        >
          {header}
        </Box>
        )}
        {blurb && (
        <Box component="span" sx={{ fontSize: '16px', color: '#4C433E', lineHeight: 1.5 }}>
          {blurb}
        </Box>
        )}
        <Box
          component="span"
          sx={{
            fontSize: 'medium', lineHeight: 1.5, textAlign: 'left', ...messageStyle
          }}
        >
          {message}
        </Box>
      </Box>
      {showClose && (
      <IconButton
        size="small"
        aria-label="close"
        onClick={handleClose}
        sx={{ marginLeft: 'auto' }}
      >
        {close}
      </IconButton>
      )}
    </Box>
  )
}

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  blurb: PropTypes.string.isRequired, // to add a message before the link
  showClose: PropTypes.bool, // can show close icon or not
  onClose: PropTypes.func.isRequired,
  alertStyle: PropTypes.oneOf(['error-alert', 'info-alert', 'success-alert', 'warning-alert', 'short-error-alert', 'short-info-alert', 'short-success-alert', 'short-warning-alert', 'success-one-line']).isRequired,
  headerText: PropTypes.string.isRequired, // can change the headertext here or make null
  customStyles: PropTypes.shape({
    textAlign: PropTypes.string,
    color: PropTypes.string,
    margin: PropTypes.string
  })
}

Alert.defaultProps = {
  showClose: true,
  customStyles: {}
}
export default Alert
