import { React, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { handleUnauthenticated, isAuthenticated } from '../../util/auth'
import { useUserData } from '../user/useUserData'

import {
  selectUserObject,
  selectUserCallStatus,
  fetchUser,
} from '../user/userSlice'
import {
  selectProfilePictureImage,
  selectProfilePictureStatus,
  fetchProfilePicture
} from '../user/profilePictureSlice'

const AuthGuard = (WrappedComponent) => {
  function AuthenticatedComponent(props) {
    if (!isAuthenticated()) {
      handleUnauthenticated()
      return false
    }
    sessionStorage.setItem('wasLoggedIn', 'true')

    const { userObjData, userProfilePictureData, isUserDataLoading } = useUserData(
      selectUserObject,
      selectUserCallStatus,
      selectProfilePictureImage,
      selectProfilePictureStatus,
      fetchUser,
      fetchProfilePicture,
      useSelector,
      useDispatch
    )
    const userObjStatus = useSelector(selectUserCallStatus)
    const dispatch = useDispatch()

    useEffect(() => {
      if (userObjStatus === 'idle') {
        dispatch(fetchUser())
      }
    }, [userObjStatus, dispatch, fetchUser])

    return (
      <WrappedComponent
          /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
        userObjData={userObjData}
        userProfilePictureData={userProfilePictureData}
        isUserDataLoading={isUserDataLoading}
      />
    )
  }

  return AuthenticatedComponent
}

export default AuthGuard
